import React, { useState, useEffect } from 'react';
import Menu from '../static/menu';
import Footer from '../static/footer';
import Slider0 from './../images/slider/Slide-home-0.jpg'
import Slider7 from './../images/slider/Slide-home-7.jpg'
import Simbolo from './../images/favicon_laehomes_blanco.svg'
import Slider3 from './../images/Slide-home-3.jpg'
import SlideOficinas from './../images/slider/Slide-oficinas.png';
import SlideVirune from './../images/slider/Slide-villa-irune.png';
import SlideVpirineos from './../images/slider/Slide-villa-pirineos.png';
import Inmueble1 from './../images/Inmueble-1.jpg'
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import SEO from '../utilities/SEO';

const Inicio = () => {
    const { t, i18n } = useTranslation("global");
    const property = [1,2,3,4,5,6]
    const route = useLocation();
    const [an, setAn] = useState('');
    useEffect(()=>{ 
        const scrollTo = (dest) => {
            let element = document.getElementById(dest);
            element.scrollIntoView({behavior:'smooth', block:'end'});   
        };
        if(route.pathname == "/firma"){
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }        
        if(route.pathname == "/contacto"){
            setTimeout(() => scrollTo(route.hash.split('#')[1]), 100);
        }        
    },[an]);
    return ( 
        <>
            {SEO()}
            <Menu/>
            <div className="container-fluid px-0">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {/* oficinas */}
                        <div className="carousel-item active">
                            <img className="w-100 img-banner" src={SlideOficinas} alt="img"/>
                            <div className="overlay"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <h2 className="display-4 text-white mb-1 animated slideInDown">
                                                {t("inicio.slideOficinas").toUpperCase()}
                                            </h2>
                                            <p 
                                                style={{fontFamily:'Times New Roman, Times, serif' , fontSize:'22px'}}
                                                dangerouslySetInnerHTML={{ __html: t("inicio.miniPOficinas").toUpperCase() }}
                                            />
                                            <p>
                                                <a href="/oficinas" className='btn btn-outline-blanco'>
                                                    {t("inicio.btnConocenos").toUpperCase()}
                                                </a>
                                            </p>  
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* VIRUNE */}
                        <div className="carousel-item">
                            <img className="w-100 img-banner" src={SlideVirune} alt="img"/>
                            <div className="overlay"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <h2 
                                                className="display-4 text-white mb-1 animated slideInDown" 
                                                dangerouslySetInnerHTML={{ __html: t("inicio.slideVirune").toUpperCase() }} 
                                            />
                                            <p className='text-center' style={{fontFamily:'Times New Roman, Times, serif' , fontSize:'22px'}}>
                                                {t("inicio.miniPVirune").toUpperCase()}
                                            </p>
                                            <p>
                                                <Link target='_blank' to="https://villairune.laehomes.com/" className='btn btn-lae'>
                                                    {t("inicio.btnApuntate").toUpperCase()}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* VILLA PIRINEOS */}
                        {/* <div className="carousel-item">
                            <div className="overlay"></div>
                            <img className="w-100 img-banner" src={SlideVpirineos} alt="img"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <div className="col-lg-12 text-center">
                                                <h2 
                                                    className="display-4 text-white mb-1 animated slideInDown"
                                                    dangerouslySetInnerHTML={{ __html: t("inicio.slideVpirineos").toUpperCase() }} 
                                                />
                                                    
                                            
                                                <p 
                                                    dangerouslySetInnerHTML={{ __html: t("inicio.miniPVpirineos").toUpperCase() }} 
                                                    className='text-center' 
                                                    style={{fontFamily:'Times New Roman, Times, serif' , fontSize:'22px'}}
                                                />
                                                    
                                                <p>
                                                    <Link target='_blank' to="https://villapirineo.laehomes.com/" className='btn btn-lae'>
                                                        {t("inicio.btnApuntate").toUpperCase()}
                                                    </Link>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="carousel-item">
                            <img className="w-100 img-banner" src={Slider0} alt="img"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <h2 className="display-4 text-white mb-1 animated slideInDown">{t("inicio.slider1").toUpperCase()}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="carousel-item">
                            <img className="w-100 img-banner" src={Slider1} alt="img"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <div className="col-lg-12 text-center">
                                                <h2 className="display-4 text-white mb-1 animated slideInDown">{t("inicio.slider2").toUpperCase()}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="carousel-item">
                            <img className="w-100 img-banner" src={Slider6} alt="img"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <div className="col-lg-12 text-center">
                                                <h2 className="display-4 text-white mb-1 animated slideInDown">{t("inicio.slider3").toUpperCase()}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100 img-banner" src={Slider4} alt="img"/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 text-center">
                                            <div className="col-lg-12 text-center">
                                                <h2 className="display-4 text-white mb-1 animated slideInDown" dangerouslySetInnerHTML={{ __html: t("inicio.slider4").toUpperCase() }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{t("inicio.anterior")}</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{t("inicio.siguiente")}</span>
                    </button>
                </div>
            </div>
            <div className="linea-1"></div>
            <div className="container-xxl py-5 my-5">
                <div className="container">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "700px"}}>
                        <h1 className='mb-4'>{t("inicio.textoValorador").toUpperCase()}</h1>
                        <a href={'https://betterplaceapp.com/valorar/lae-homes/u/38285?locale=' + i18n.language || 'es'} hrefLang={i18n.language || 'es'} className="btn btn-borde px-3" target='_blank'>{t("inicio.calculaValorador").toUpperCase()}</a>
                    </div>
                </div>
            </div>
            <div className="linea-2"></div>
            <div className="container-fluid parallax py-5 d-flex" style={{alignItems:"center"}}>
                <div className="container py-5 my-5">
                    <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                    <img src={Simbolo} alt="Lae Homes" width="50px" />
                        <h1 className="display-6 my-4 text-white">{t("inicio.nuevoConcepto")}</h1>
                        <p className="fs-6 fw-medium mb-4 text-white">{t("inicio.nuevoConceptoP")}</p>
                        {/* <button type="button" className="btn btn-borde">Conócenos</button> */}
                        <Link className="btn btn-borde border-white text-white" to="/firma#equipo" onClick={()=>setAn('equipo')}>{t("inicio.conocenos")}</Link>
                    </div>
                </div>
                
            </div>
            <div className="linea-1"></div>
            <div className="container-xxl py-5">
                <div className="container py-5 my-5 wow fadeInUp" data-wow-delay="0.1s" >
                    <div className="text-center mx-auto " style={{maxWidth: "800px"}}>
                        <h1 className="display-6 mb-2">{t("inicio.propObraNueva")}</h1>
                        {/* <p className="fs-6 fw-medium color-primary mb-4">{t("proximamente")}</p> */}
                        <p className="fs-6 fw-medium mb-4">{t("inicio.propObraNuevaP")}</p>
                        <Link to="/contacto" className="btn btn-borde">{t("contacta")}</Link>
                    </div>
                    
                </div>
            </div>
            <div className="linea-2"></div>
            <div className="container-fluid py-5 bg-imagen d-flex" style={{ alignItems: 'center'}}>
                <div className="container">
                    <div className="col-lg-4 col-md-6 col-sm-8 p-5 bg-white text-center text-md-start wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                        {/* <img src={Slider3} alt="Imagen" /> */}
                        {/* <h1 className="mb-3">{t("inicio.dondeVivir")}</h1> */}
                        {/* <p className="fw-low color-secondary">Te ayudaremos a encontrar la propiedad que buscas. <a href="https://www.google.com/maps/d/u/0/edit?mid=1HMmCykaA7JoWrSG13-YjhuOCGsKkJVA&usp=sharing" target={"_blank"}> <u>Contacta con nuestras oficinas.</u></a></p> */}
                        <p className="fw-low color-secondary">
                            <label className='negrita'>{t("inicio.label1")}</label>
                            {t("inicio.textoContactar")}   
                        </p>
                        <Link to="/oficinas#oficinas" onClick={()=>setAn('oficinas')}> <u>{t("inicio.contactaOficinas")}</u></Link>
                    </div>
                </div>
            </div>
          
            <Footer />

            <style jsx="true">{`
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad según tus necesidades */
                    z-index: 1;
                }
                .negrita {
                    font-weight: bold;
                }
                .btn-lae {
                    background-color:#9F8A52;
                    border-radius: 1px;
                    font-size:14px;
                    padding: .475rem .85rem;
                    color:white;
                }
                .btn-outline-blanco {
                    background-color: transparent;
                    border: 1px solid white;
                    border-bottom-width: 3px;
                    border-radius: 1px;
                    font-size: 14px;
                    padding: 1rem 3.85rem;
                    color: white;
                    transition: background-color 0.3s ease; 
                }
                
                .btn-outline-blanco:hover {
                    background-color: #9F8A52; 
                    color: white;
                }
                .parallax{
                    background-image: url(${`'${Slider7}'`});
                    min-height: 500px;
                    background-attachment: fixed;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                .bg-imagen{
                    background-image: url(${`'${Slider3}'`});
                    min-height: 800px;
                    background-position: center;
                    background-position-x: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    background-position-x: right;
                    margin: 0;
                }
                
            `}</style>
        </>
    );
}
 
export default Inicio;